<template>
  <div class="" v-if="item">
    <div class="w-56 overflow-hidden">
      <a
        :href="message"
        target="_blank"
        class="cursor-pointer font-dinpro font-normal text-agrogo-yellow-1 text-xl text-left mb-3 md:font-normal flex"
      >
        <span class="icon-whatsapp text-xl mr-4 md:mr-2"></span>
        <p class="break-all">Chatea con nosotros</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      telephone: null,
    };
  },
  methods: {
    loadUrl() {
      // return `https://api.whatsapp.com/send?phone=+573108321917&text=Hola%20AGRO%20GO%20👍🏽%0ABienvenido%20al%20canal%20de%20atención%20de%20AGRO%20GO%20🚜🌿🐮%0AEn%20que%20podemos%20ayudarte%20el%20día%20de%20hoy?`;
      // return `https://api.whatsapp.com/send?phone=+573108321917&text=Hola%20AGRO%20GO%20👍🏽%0A✅%20Me%20interesa%20saber%20más%20de%20esta%20Finca:%0A`;
    },
  },
};
</script>
