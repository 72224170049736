<template>
  <div class="" v-if="item">
    <div class="w-56 overflow-hidden">
      <a
        href="mailto:info@agrogo.com.co"
        target="_newtab"
        class="font-dinpro font-normal text-agrogo-yellow-1 text-xl text-left mb-3 md:font-normal flex"
      >
        <span class="icon-mail text-xl mr-4 md:mr-2"></span>
        <p class="break-all" v-html="item.content"></p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      email: null,
    };
  },
};
</script>

<style></style>
